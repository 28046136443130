import React from 'react';
import { Link } from "react-router-dom";
import List from '@mui/material/List';
import { useState, useEffect} from 'react';
import { useAppStorePersist, useAppStore } from '../../utils/dataProvider'
import { Checkbox} from '@mui/material';
import {Table, Button, ButtonGroup } from 'react-bootstrap';
import { get } from '../../utils/api';

function CompanyLink(props) {
    const calculateScore = (value, ok, negative = false) => {
        if((!negative && value > ok) || (negative && value < ok)){
            return true;
         }  else {
            return false;
         }
     };

    const calculateScoreByCorrelation = (value, score=1) => {
    if(value > 0.01 && score > 0.3){
        return true;
        }  else {
        return false;
        }
    };

    const ownProfit = useAppStorePersist((state) => state.ownProfit)
    const ownCompanies = useAppStorePersist((state) => state.ownCompanies)
    const addOwnCompany = useAppStorePersist((state) => state.addOwnCompany)
    const dropOwnCompany = useAppStorePersist((state) => state.dropOwnCompany)

    const {companyList} = props;
    const data = companyList;

    /* TODO CALCULATIONS TO BACKEND */
    const priceByEPS = 1 / ownProfit * 100 * data?.report?.AVG5?.earnings_Per_Share;
    const priceByFreeCashFlow = 1 / ownProfit * 100 * data?.report?.AVG5?.free_Cash_Flow / data?.report?.lastyear_number_Of_Shares;


    const profitByEPS = data?.report?.AVG5?.earnings_Per_Share / data?.price;
    const priceByEPSScore = calculateScore(profitByEPS, 0.06);
    const profitByFreeCashFlow = data?.report?.AVG5?.free_Cash_Flow / data?.report?.lastyear_number_Of_Shares / data?.price;
    const priceByFreeCashFlowScore = calculateScore(profitByFreeCashFlow, 0.06);
    const ROE = data?.report?.AVG5?.profit_To_Equity_Holders / data?.report?.AVG5?.total_Equity;
    const ROEScore = calculateScore(ROE, 0.15);
    const ROI = data?.report?.AVG5?.profit_To_Equity_Holders / data?.report?.AVG5?.invested_capital;
    const ROIScore = calculateScore(ROI, 0.10);

    const regressionEPSPercent = data?.report?.regressionEPSPercent;
    const regressionFreeCashFlowPercent = data?.report?.regressionFreeCashFlowPercent;
    const regressionEPSScore = data?.report?.regressionEPSScore;
    const regressionEPSHitScore = calculateScoreByCorrelation(regressionEPSPercent / 100, regressionEPSScore?.r2);
    const regressionFreeCashFlowScore = data?.report?.regressionFreeCashFlowScore;

    const ps = (data?.report?.lastyear_number_Of_Shares * data?.price) / data?.report?.AVG5?.revenues;
    const psScore = calculateScore(ps, 1, true);
    const ds = data?.report?.reportLastyear?.net_Debt / data?.report?.AVG5?.revenues;
    const dsScore = calculateScore(ds, 0.1, true);
    const dividend = data?.report?.AVG5?.dividend / data?.price;
    const dividendScore = calculateScore(dividend, 0.04, false);

    const totalScore = priceByEPSScore + priceByFreeCashFlowScore + ROEScore + ROIScore + psScore + dsScore + dividendScore + regressionEPSHitScore;




    const calculateColor = (value, ok, negative = false) => {
       if((!negative && value > ok) || (negative && value < ok)){
            return `rgb(0,200,0)`; //green
        }  else {
            return `rgb(0,0,0)`; 
        }
    };

    const calculateColorByCorrelation = (value, score) => {
       if(value > 0.01 && score > 0.3){
            return `rgb(0,200,0)`; //green
        } else {
            return `rgb(0,0,0)`; 
        }
    };
    
    return  (<tbody class="listTable">   
                <tr>
                    <td><Link to={`/financials1/${companyList.insId}`}>{companyList.name}</Link></td>
                    <td>
                        <Checkbox onClick={() => ownCompanies.find((f => f == companyList.insId)) ? dropOwnCompany(companyList.insId) : addOwnCompany(companyList.insId) } checked={ownCompanies.find((f => f == companyList.insId)) ? true : false}></Checkbox>
                    </td>
                    <td>{totalScore}</td>
                    <td>{companyList.price}</td>
                    <td style={{color: calculateColor(profitByEPS, 0.06)}}>{Math.round(profitByEPS * 100 * 100) / 100} %</td>
                    <td style={{color: calculateColor(profitByFreeCashFlow, 0.06)}}>{Math.round(profitByFreeCashFlow * 100 * 100) / 100} %</td>
                    <td style={{color: calculateColor(dividend, 0.04, false)}}>{Math.round(dividend * 100) } %</td>
                    <td style={{color: calculateColor(ROE, 0.15)}}>{Math.round(ROE * 100 * 100) / 100} %</td>
                    <td style={{color: calculateColor(ROI, 0.10)}}>{Math.round(ROI * 100 * 100) / 100} %</td>
                    <td style={{color: calculateColorByCorrelation(regressionEPSPercent / 100, regressionEPSScore?.r2)}}>{Math.round(regressionEPSPercent * 100) / 100} %({Math.round(regressionEPSScore?.r2 * 10) / 10})</td>
                    <td style={{color: calculateColorByCorrelation(regressionFreeCashFlowPercent / 100, regressionFreeCashFlowScore?.r2)}}>{Math.round(regressionFreeCashFlowPercent * 100) / 100} %({Math.round(regressionFreeCashFlowScore?.r2 * 10) / 10})</td>
                    <td style={{color: calculateColor(ps, 1, true)}}>{Math.round(ps * 100) / 100} x</td>
                    <td style={{color: calculateColor(ds, 0.1, true)}}>{Math.round(ds * 100) } %</td>
                </tr>
            </tbody>)
            
}

   
  export default function Page() {
    const countriesMap = [
        {
          "id": 1,
          "name": "Sverige"
        },
        {
          "id": 2,
          "name": "Norge"
        },
        {
          "id": 3,
          "name": "Finland"
        },
        {
          "id": 4,
          "name": "Danmark"
        }
      ];

    const [companyListData, setCompanyListData] = useState(null)
    const [itemLoadingNum, setitemLoadingNum] = useState(0)
    const [company, setCompany] = useState([])
    const ownCompanies = useAppStorePersist((state) => state.ownCompanies)
    const setLoading = useAppStore((state) => state.setLoading)

    
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const country = query.get('country') ? query.get('country') : 3

        setLoading(true);
        get("/listallwithreports/" + country)
          .then((data) => {
            if(data.code !== 401){
                setCompanyListData(data)
            } else {
                window.location.href = "/login"
            }
            setLoading(false);
          })
    }, [])

    const setSearchParams = function(key, value) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        window.location.search = searchParams.toString();
    }

    //console.log("listData", companyListData)
    return (
        <div style={{width: '100%', maxWidth: 1100,"margin":"auto", backgroundColor: "white", padding: "20px", textAlign: "center"}}>
            <ButtonGroup aria-label="Basic example">
                <Button onClick={() => setSearchParams("country", 3)} variant="secondary">FI</Button>
                <Button onClick={() => setSearchParams("country", 1)} variant="secondary">SE</Button>
                <Button onClick={() => setSearchParams("country", 2)} variant="secondary">NO</Button>
                <Button onClick={() => setSearchParams("country", 4)} variant="secondary">DK</Button>
            </ButtonGroup>
            <List>
                <Table striped bordered hover size="sm">
                    <thead className="listTable">
                        <tr>
                            <th>Nimi</th>
                            <th>Salkussa</th>
                            <th>Pisteet</th>
                            <th>Hinta</th>
                            <th>TulosAVG5</th>
                            <th>KassavirtaAVG5</th>
                            <th>Osinko</th>
                            <th>ROEAVG5</th>
                            <th>ROIAVG5</th>
                            <th>TulosTrend</th>
                            <th>KassavirtaTrend</th>
                            <th>PS</th>
                            <th>DS</th>
                        </tr>
                    </thead>
                {companyListData ? companyListData
                    //.sort((a, b) => (a?.reports?.potentialByFreeCashFlow || a?.reports?.potentialByEPS < (-1)) < (-1) ? 1 : (b?.reports?.potentialByFreeCashFlow + b?.reports?.potentialByEPS) - (a?.reports?.potentialByFreeCashFlow + a?.reports?.potentialByEPS))
                    .sort((b, a) => ((a?.report?.AVG5?.earnings_Per_Share / a?.price) + (a?.report?.AVG5?.free_Cash_Flow / a?.report?.lastyear_number_Of_Shares / a?.price)) - ((b?.report?.AVG5?.earnings_Per_Share / b?.price) + (b?.report?.AVG5?.free_Cash_Flow / b?.report?.lastyear_number_Of_Shares / b?.price)))
                    .sort((a, b) => {
                        if(a?.report?.AVG5?.earnings_Per_Share < 0 || a?.report?.AVG5?.free_Cash_Flow < 0){
                            return 1;
                        } 
                        if(b?.report?.AVG5?.earnings_Per_Share < 0 || b?.report?.AVG5?.free_Cash_Flow < 0){
                            return -1;
                        }
                    })
                    .sort((a, b) => {
                        if(ownCompanies.find((f => f === a?.insId)) === ownCompanies.find((f => f === b?.insId))){
                            return 0;
                        } else if(ownCompanies.find((f => f === a?.insId))){
                            return -1;
                        } else {
                            return 1;
                        }
                    })
                    .map((companyList, index) => {
                    return <CompanyLink key={companyList.id} companyList={companyList}/>
                            
                }): "Loading"}
                </Table>
            </List>
        </div>
        )
  }