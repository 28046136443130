import React, { useState } from 'react'


export default function Login() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const loginToken = urlParams.get('token');
    const loginEmail = urlParams.get('email');
    if(loginToken && loginEmail){
      fetch(process.env.REACT_APP_API_URL + `/login/login?email=${loginEmail}&token=${loginToken}`)
            .then(response => response.json())
            .then(data => {
              console.log(data)
              window.localStorage.setItem('jwt-token', data.token)
              window.location.replace(`/`);
            })
            .catch(error => console.error(error));
    }

    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [type, setType] = useState("email");
    const [response, setResponse] = useState({});

    const handleLink = () => {
        if(type === "email") {
            fetch(process.env.REACT_APP_API_URL + `/login/register?email=${email}`)
            .then(response => response.json())
            .then(data => setResponse(data))
            .catch(error => console.error(error));
        } else {
            fetch(process.env.REACT_APP_API_URL + `/login?phone=${phone}`)
            .then(response => response.json())
            .then(data => setResponse(data))
            .catch(error => console.error(error));
        }
    }
  

    return (
        <div className="login">
            <div >
              <h1 className='title'>Kirjaudu</h1> 
              {/*<button onClick={() => setType("email")}>email</button>*/}
              {/*<button onClick={() => setType("sms")}>sms</button>*/}
              <br></br>
              {type === "email" && (<input onChange={e => setEmail(e.target.value)} id="name" name="name" type="text" placeholder="email"/>)}
              {/*type === "sms" && (<input onChange={e => setPhone(e.target.value)} id="phone" name="phone" type="text" placeholder="phone"/>)*/}
              <br></br>
              <button onClick={() => handleLink()}>Send link</button>
              <br></br>
              <b>
                {response.code === 200 && response.success ? "Email sent" : ""}
              </b>
            </div>
        </div>)

}