const API_URL = process.env.REACT_APP_API_URL;

export const get = async (url) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + window.localStorage.getItem('jwt-token')
        },
    };
    const response = fetch(API_URL + url, requestOptions).then(response => response.json())
    return response;    
}

export const post = async (url, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + window.localStorage.getItem('jwt-token')
        },
        body: JSON.stringify(data)
      };
    const response = fetch(API_URL + url, requestOptions).then(response => response.json())
    return response;
   
}