import { useState, useEffect, Fragment} from 'react';
import { useParams } from 'react-router-dom';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, BarChart, Bar} from 'recharts';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { stringToColour } from '../../utils/utils'
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//import regression from 'regression';
import { useAppStorePersist } from './../../utils/dataProvider'
import { get } from '../../utils/api';


const TulosLaskelma = (props) => {
  //const data = props.data;
  const valueType = "Tuloslaskelma";
  const [chartType, setChartType] = useState("profit_To_Equity_Holders")
  const markkinaArvo = props.price * props.data?.lastyear_number_Of_Shares
  const markkinaArvoByEps = props.priceByEPS *  props.data?.lastyear_number_Of_Shares;

  const VainTulos = ["profit_To_Equity_Holders"];
  const VainEPS = ["earnings_Per_Share"];


  let includes = [];
  if(chartType === "gross_Income"){
    includes.push("gross_Income");
    includes.push("revenues");
  } else if(chartType === "operating_Income"){
    includes.push("operating_Income");
    includes.push("revenues");
  } else if(chartType === "profit_To_Equity_Holders"){
    includes.push("profit_To_Equity_Holders");
    includes.push("revenues");
  } 


  const chartdataHeaders = includes;
  console.log("chartdataHeaders", includes)

  const chartdata = props.data?.reportsCombined.reverse();
  

    console.log("chartdata", chartdata)


    return (
      <div style={{width: "100%"}}>

        <Button onClick={() => setChartType("gross_Income")} variant="outlined">gross_Income</Button>
        <Button onClick={() => setChartType("operating_Income")} variant="outlined">operating_Income</Button>
        <Button onClick={() => setChartType("profit_To_Equity_Holders")} variant="outlined">profit_To_Equity_Holders</Button>


        <div style={{width: "100%", height: "300px"}}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={chartdata}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <ReferenceLine isFront={true} y={markkinaArvo} alwaysShow={true} label="Markkina-arvo" stroke="red" strokeDasharray="3 3" />
              <ReferenceLine isFront={true} y={markkinaArvoByEps} alwaysShow={true} label="Valuaatio by EPS" stroke="blue" strokeDasharray="3 3" />

              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              {chartdataHeaders.map((header, index) => 
                (<Bar type="monotone" dataKey={header} key={index} stackId={index === 0 ? 0 : 1} stroke={index === 0 ? "#000000" : stringToColour(header)} fill={index === 0 ? "#000000" : stringToColour(header)} />)
              )}           
            </BarChart>
          </ResponsiveContainer>
        </div>
        <br></br>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>

              <TableRow>
                <TableCell key={-1}></TableCell>
                {chartdata.map((data, index) => 
                  <Fragment key={index}>
                    <TableCell key={index}><b>{data.year}</b></TableCell>
                    <TableCell key={index + "asd"}></TableCell>
                  </Fragment>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {chartdataHeaders.map((header, index) =>    
                <TableRow key={index}>
                  <TableCell component="th" scope="row" key={-1}><b>{header}</b></TableCell>
                  {chartdata.map((data, index) => 
                    <Fragment key={index}>
                      <TableCell key={index}>{data[header]}</TableCell>
                      <TableCell key={index + "asd"}>{data["revenues"] ? (Math.round(data[header] / data["revenues"] * 100)) : 0}%</TableCell>
                    </Fragment>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
}

const Tase = (props) => {
  //const data = props.data;
  const valueType = "Tuloslaskelma";
  const [chartType, setChartType] = useState("Assets")
  const markkinaArvo = props.price * props.data?.lastyear_number_Of_Shares
  //const markkinaArvoByEps = props.priceByEPS *  props.data?.lastyear_number_Of_Shares;

  const VainTulos = ["profit_To_Equity_Holders"];
  const VainEPS = ["earnings_Per_Share"];


  let includes = [];
  if(chartType === "Assets"){
    includes.push("total_Assets");
    includes.push("current_Assets");
    includes.push("non_Current_Assets");
    includes.push("cash_And_Equivalents");

  } else if(chartType === "Debts"){
    includes.push("revenues");
    includes.push("net_Debt");
    includes.push("profit_To_Equity_Holders");
  } else if(chartType === "DebtsProfit"){
    includes.push("net_Debt");
    includes.push("profit_To_Equity_Holders");
    includes.push("free_Cash_Flow");
  } 


  const chartdataHeaders = includes;
  console.log("chartdataHeaders", includes)

  //const chartdata = props.data?.reportsYear.reverse();
  const chartdata = props.data?.reportsCombined.reverse();
  

    console.log("chartdata", chartdata)


    return (
      <div style={{width: "100%"}}>

        <Button onClick={() => setChartType("Assets")} variant="outlined">Assets</Button>
        <Button onClick={() => setChartType("Debts")} variant="outlined">Debts</Button>
        <Button onClick={() => setChartType("DebtsProfit")} variant="outlined">Debts & profit</Button>


        <div style={{width: "100%", height: "300px"}}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={chartdata}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <ReferenceLine isFront={true} y={markkinaArvo} alwaysShow={true} label="Markkina-arvo" stroke="red" strokeDasharray="3 3" />

              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              {chartdataHeaders.map((header, index) => 
                (<Bar type="monotone" dataKey={header} key={index} stackId={index} stroke={index === 0 ? "#000000" : stringToColour(header)} fill={index === 0 ? "#000000" : stringToColour(header)} />)
              )}           
            </BarChart>
          </ResponsiveContainer>
        </div>
        <br></br>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>

              <TableRow>
                <TableCell key={-1}></TableCell>
                {chartdata.map((data, index) => 
                  <Fragment key={index}>
                    <TableCell key={index}><b>{data.year}</b></TableCell>
                  </Fragment>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {chartdataHeaders.map((header, index) =>    
                <TableRow key={index}>
                  <TableCell component="th" scope="row" key={-1}><b>{header}</b></TableCell>
                  {chartdata.map((data, index) => 
                    <Fragment key={index}>
                      <TableCell key={index}>{data[header]}</TableCell>
                    </Fragment>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
}


const Kassavirta = (props) => {
  //const data = props.data;
  const [chartType, setChartType] = useState("CashFlow")
  const markkinaArvo = props.price * props.data?.lastyear_number_Of_Shares
  const markkinaArvoByCashFlow = props.priceByFreeCashFlow *  props.data?.lastyear_number_Of_Shares;


  let includes = [];
  if(chartType === "CashFlow"){
    includes.push("free_Cash_Flow");
  }
  if(chartType === "CashFlowMore"){
    includes.push("cash_Flow_From_Operating_Activities");
    includes.push("cash_Flow_From_Investing_Activities");
    includes.push("cash_Flow_From_Financing_Activities");
    includes.push("cash_Flow_For_The_Year");
    includes.push("free_Cash_Flow");
  }
  if(chartType === "CashFlowDividend"){
    includes.push("free_Cash_Flow");
    includes.push("totaldividend");
  } 


  const chartdataHeaders = includes;
  console.log("chartdataHeaders", includes)

  //const chartdata = props.data?.reportsYear.reverse();
  const chartdata = props.data?.reportsCombined.reverse();
  

    console.log("chartdata", chartdata)


    return (
      <div style={{width: "100%"}}>

        <Button onClick={() => setChartType("CashFlow")} variant="outlined">CashFlow</Button>
        <Button onClick={() => setChartType("CashFlowMore")} variant="outlined">CashFlowMore</Button>
        <Button onClick={() => setChartType("CashFlowDividend")} variant="outlined">CashFlow&Dividend</Button>


        <div style={{width: "100%", height: "300px"}}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={chartdata}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <ReferenceLine isFront={true} y={markkinaArvo} alwaysShow={true} label="Markkina-arvo" stroke="red" strokeDasharray="3 3" />
              <ReferenceLine isFront={true} y={markkinaArvoByCashFlow} alwaysShow={true} label="Valuaatio by Cashflow" stroke="blue" strokeDasharray="3 3" />

              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              {chartdataHeaders.map((header, index) => 
                (<Bar type="monotone" dataKey={header} key={index} stackId={index} stroke={index === 0 ? "#000000" : stringToColour(header)} fill={index === 0 ? "#000000" : stringToColour(header)} />)
              )}           
            </BarChart>
          </ResponsiveContainer>
        </div>
        <br></br>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>

              <TableRow>
                <TableCell key={-1}></TableCell>
                {chartdata.map((data, index) => 
                  <Fragment key={index}>
                    <TableCell key={index}><b>{data.year}</b></TableCell>
                  </Fragment>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {chartdataHeaders.map((header, index) =>    
                <TableRow key={index}>
                  <TableCell component="th" scope="row" key={-1}><b>{header}</b></TableCell>
                  {chartdata.map((data, index) => 
                    <Fragment key={index}>
                      <TableCell key={index}>{data[header]}</TableCell>
                    </Fragment>
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
}


export const TurnOffDefaultPropsWarning = () => {
  // Override console.error
  // This is a hack to suppress the warning about missing defaultProps in the recharts library
  // @link https://github.com/recharts/recharts/issues/3615
  const error = console.error;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  console.error = (...args) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    if (/defaultProps/.test(args[0])) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    error(...args);
  };

  return <></>;
};




export default function Page({ params }) {
    let { id } = useParams();
    const [data, setData] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const ownProfit = useAppStorePersist((state) => state.ownProfit)

    const [price, setPrice] = useState(0)
    const [name, setName] = useState("")
    
    

    useEffect(() => {
      
      get("/reports/" + id)
        .then((data) => {
          if(data.code !== 401){
            setData(data)
          } else {
              window.location.href = "/login"
          }
          setLoading(false)
        })
    }, [id])



    useEffect(() => {
      get("/list/" + id)
        .then((data) => {
          if(data.code !== 401){
            setPrice(data.price)
            setName(data.name)
          } else {
              window.location.href = "/login"
          }
        })
    }, [id])

  


    const priceByEPS = 1 / ownProfit * 100 * data?.AVG5?.earnings_Per_Share;
    const priceByFreeCashFlow = 1 / ownProfit * 100 * data?.AVG5?.free_Cash_Flow / data?.lastyear_number_Of_Shares;
    const ps = (data?.lastyear_number_Of_Shares * price) / data?.AVG5?.revenues;
    const ds = data?.reportLastyear?.net_Debt / data?.AVG5?.revenues;

    const profitByEPS = data?.AVG5?.earnings_Per_Share / price;
    const profitByFreeCashFlow = data?.AVG5?.free_Cash_Flow / data?.lastyear_number_Of_Shares / price;
    

    if(isLoading && window){
      window.scrollTo(0, 0);
    }
      
    return (
      <div style={{width: '100%', maxWidth: 960,"margin":"auto", backgroundColor: "white", padding: "20px", textAlign: "center"}}>
        {isLoading ? <CircularProgress /> :
          <>
            <TurnOffDefaultPropsWarning></TurnOffDefaultPropsWarning>
            <Typography variant="h2">{name}</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell><b>Hinta</b></TableCell><TableCell><b>Tulosperusteinen</b></TableCell><TableCell><b>Kassavirtaperusteinen</b></TableCell><TableCell><b>P/S</b></TableCell><TableCell><b>Nettovelka/Liikevaihto</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{price}</TableCell>
                    <TableCell><span style={{color: profitByEPS > 0.5 ? "green" : "orange"}}>{Math.round(profitByEPS * 100) / 100 * 100} %</span></TableCell>
                    <TableCell><span style={{color: profitByFreeCashFlow > 0.5 ? "green" : "orange"}}>{Math.round(profitByFreeCashFlow * 100) / 100 * 100} %</span></TableCell>
                    <TableCell><span style={{color: ps < 1 ? "green" : "red"}}>{Math.round(ps * 100) / 100}  {Math.round((1 - ps) * 100)} %</span></TableCell>
                    <TableCell><span>{Math.round(ds * 100) / 100} x</span></TableCell>
                  </TableRow>               
                </TableBody>
              </Table>
            </TableContainer>    
            <br></br>
            <Divider></Divider>
            <br></br>
            <Typography variant="h3">Tuloslaskelma</Typography>
            <br></br>
            {(!isLoading) && <TulosLaskelma price={price} priceByEPS={priceByEPS} data={data}></TulosLaskelma>}
            <br></br>
            <Divider></Divider>
            <br></br>
            <Typography variant="h3">Tase</Typography>
            <br></br>
            {(!isLoading) && <Tase price={price} data={data}></Tase>}
            <br></br>
            <Divider></Divider>
            <br></br>
            <Typography variant="h3">Kassavirta</Typography>
            <br></br>
            {(!isLoading) && <Kassavirta  priceByFreeCashFlow={priceByFreeCashFlow} price={price} data={data}></Kassavirta>}
            <br></br>
                    
          </>
        }
      </div>
    )
  }