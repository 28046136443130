import { useAppStorePersist } from '../utils/dataProvider'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';


export default function Header() {
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])
  //const [ownProfit, setOwnProfit] = useState(10);
  const ownProfit = useAppStorePersist((state) => state.ownProfit)
  const setOwnProfit = useAppStorePersist((state) => state.setOwnProfit)

  const handleChange = (event, newValue) => {
    setOwnProfit(newValue);
  };

  function refresh() {
    const res =  fetch(process.env.REACT_APP_API_URL + "/cleancache", { cache: 'no-store' })
    console.log("clean cache");
    return null;
  };

  return (
        <Box sx={{ flexGrow: 1 }}>
          {isClient &&
          <AppBar position="fixed" sx={{backgroundColor: "#999999"}}>
            <Toolbar>
              <IconButton
                  href={`/`}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <ListAltIcon/>
              </IconButton>
              <IconButton
                onClick={() => refresh()}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <CachedIcon></CachedIcon>
              </IconButton>
              <Box edge="end" sx={{ display: "none", zIndex: 999, left: "100px", position: "absolute", width: "calc(100% - 200px)", textAlign: "center"}}>
                <Stack alignItems="center">
                  <Slider sx={{width: 200}} aria-label="Volume" value={ownProfit} onChange={handleChange} />
                </Stack>
                <Typography>{ownProfit} % {Math.round(1 / ownProfit * 1000)/10}</Typography>
              </Box>
            </Toolbar>
          </AppBar>
          }
          <Box sx={{height: "64px"}}></Box>
        </Box> 
  );
}
