import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export const useAppStorePersist = create(
    persist(
        (set) => ({
            ownProfit: 6,
            ownCompanies: [],
            setOwnProfit: (profit) => set((state) => ({ ownProfit: profit })),
            addOwnCompany: (company) => set((state) => ({ ownCompanies: [...state.ownCompanies, company] })),
            dropOwnCompany: (company) => set((state) => ({ ownCompanies: state.ownCompanies.filter((c) => c !== company) })),
        }),
        {
            name: 'app-storage',
            storage: createJSONStorage(() => localStorage)
        }
    )
)


export const useAppStore = create(
    
        (set) => ({
            loading: false,
            setLoading: (loading) => set((state) => ({ loading: loading })),
        })
)

//export default useAppStorePersist;